import Head from "next/head";

type Nullable<T> = T | null;

type MetadataProps = {
  type: Nullable<string>;
  title: Nullable<string>;
  image: Nullable<string>;
  siteName: Nullable<string>;
  canonical: Nullable<string>;
  description: Nullable<string>;
};

export default function Metadata(props: Partial<MetadataProps>) {
  const { type, title, description, image, siteName, canonical } = props;

  return (
    <Head>
      {/* <!-- Common --> */}
      <title>{withDefault("title", title)}</title>
      <link rel="canonical" href={withDefault("canonical", canonical)} />
      <meta
        name="description"
        content={withDefault("description", description)}
      />

      {/* <!-- Open Graph --> */}
      <meta property="og:title" content={withDefault("title", title)} />
      <meta
        property="og:description"
        content={withDefault("description", description)}
      />
      <meta property="og:image" content={withDefault("image", image)} />
      <meta
        property="og:site_name"
        content={withDefault("siteName", siteName)}
      />
      <meta property="og:url" content={withDefault("canonical", canonical)} />
      <meta property="og:type" content={type ?? defaults.type} />

      {/* <!-- Twitter --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={withDefault("title", title)} />
      <meta
        name="twitter:description"
        content={withDefault("description", description)}
      />
      <meta name="twitter:image" content={withDefault("image", image)} />
    </Head>
  );
}

type T = {
  [P in keyof MetadataProps]: NonNullable<MetadataProps[P]>;
};

const defaults: T = {
  type: "website",
  title: "GrowthDeputy",
  description:
    "We help businesses of all sizes achieve their next level of growth by leveraging our remote marketing team. Every Deputy is carefully selected and handles those manual and time-consuming marketing tasks so you can focus on growing your business.",
  image:
    "https://www.growthdeputy.com/images/campaign/focus-on-your-business.png",
  siteName: "GrowthDeputy",
  canonical: "https://www.growthdeputy.com/",
};

type Key = keyof MetadataProps;

function withDefault<K extends Key>(key: K, value?: MetadataProps[K]) {
  return value ?? defaults[key];
}
