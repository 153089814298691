import Metadata from "@components/Metadata";
import { getAppConfigs, WithAppConfigs } from "@utils/appConfigs";
import apolloClient from "apollo/apollo-client";
import { Cta, Cta1, Hero, HowItWorks, Testimonials } from "components/Landing";
import Layout, { WithPageLayout } from "components/Layout";
import { GET_HOME_PAGE_CONTENT } from "graphql/queries";
import { GetStaticProps } from "next";
import { ReactNode } from "react";
import { GetHomePageContent } from "types/GetHomePageContent";

interface HomePageProps {
  content: GetHomePageContent;
}

const Home: WithPageLayout<WithAppConfigs<HomePageProps>> = ({
  content,
  configs,
}) => {
  return (
    <div>
      <Metadata
        title={content.homePage?.Metadata?.title}
        description={content.homePage?.Metadata?.description}
      />

      <Hero homeContent={content.homePage} configs={configs} />
      <HowItWorks />
      <Cta homeContent={content.homePage} configs={configs} />
      <Testimonials testimonials={content.homePage?.Testimonials || []} />
      <Cta1 configs={configs} />
    </div>
  );
};

export default Home;

Home.getLayout = function getLayout(page: ReactNode) {
  return <Layout>{page}</Layout>;
};

export const getStaticProps: GetStaticProps = async () => {
  const configs = await getAppConfigs();
  const { data } = await apolloClient.query({
    query: GET_HOME_PAGE_CONTENT,
    fetchPolicy: "network-only",
  });

  return {
    revalidate: 1,
    props: {
      content: data,
      configs,
    },
  };
};
